/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'like': {
    width: 16,
    height: 15,
    viewBox: '0 0 16 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.171 6.2c.266.266.421.624.432 1v1.425c0 .182-.035.361-.1.53l-2.155 4.982a1.376 1.376 0 01-1.295.863h-6.37c-.378.01-.742-.14-1-.415a1.537 1.537 0 01-.433-1.013V6.5c.011-.376.166-.734.432-1L9.332.82l.764.764a.994.994 0 01.3.73v.232l-.7 3.254h4.475c.374-.011.737.134 1 .4zM2.856 15H.003V6.5h2.853V15z" _fill="#07F"/>'
  }
})
